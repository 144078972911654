<template src="./profile.html"></template>
<style src="./profile.scss" lang="scss" scoped></style>

<script>
import { mapGetters } from 'vuex'
import { authGetters } from '@/store/modules/auth/routines'
import BackBtn from '@/components/BackBtn'

export default {
  name: 'Profile',
  components: {
    BackBtn
  },
  data () {
    return {
      navigation: [
        {
          label: 'Account Details',
          to: { name: 'profile' }
        },
        {
          label: 'Email Address',
          to: { name: 'profile-email' }
        },
        {
          label: 'Password',
          to: { name: 'profile-password' }
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      user: authGetters.USER
    })
  }
}
</script>
